@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

html {
    scroll-behavior: smooth;
}

* {
    font-family: 'Poppins', sans-serif !important;
    transition: all 0.3s linear !important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(90deg, #f6f8fd 29px, transparent 1%) 50%, linear-gradient(#f6f8fd 29px, transparent 1%) 50%, #bfc5d3;
    background-size: 30px 30px;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, ::after, ::before {
    box-sizing: border-box;
}


@media (min-width: 1024px) {
    .responsive-hidden {
        display: none !important;
    }
}

.carousel-control-prev {
    margin-left: -120px;
}

.carousel-control-next {
    margin-right: -120px;
}

.carousel-indicators {
    display: none !important;
}

@media (max-width: 425px) {
    .carousel-control-prev {
        margin-left: -40px;
    }

    .carousel-control-next {
        margin-right: -40px;
    }
}

.p_absoulte {
    position: absolute;
}

.t_four {
    transition: 0.8s;
    top: 110px;
    left: 50%;
    transform: translateX(60%);
}

.t_two {
    right: 220px;
    bottom: 185px;
    transition: 0.8s;
}

.t_first {
    left: 80px;
    top: 275px;
    transition: 0.8s;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    padding-left: 1.3em;
}

.modal-content {
    border-radius: 20px !important;
}

@media (min-width: 1024px) {
    .dropdown-item {
        transition: 0.8s;
        padding: 1rem 1rem !important;
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .dropdown .dropdown-menu {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;

    }

    .dropdown.show .dropdown-menu {
        opacity: 1;
    }
}

.slideDown, .slideUp {
    opacity: 0;
}

.slideDown {
    animation: slideDown ease .4s forwards .6s;
}

.slideUp {
    animation: slideUp ease .5s forwards 1.2s;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-40px);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(40px);
        opacity: 1;
    }
}

.rhap_time {
    display: none !important;
}

.rhap_progress-bar {
    display: none;
}

.rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 10% !important;
    padding: 0 !important;
    background-color: #f5f8fd !important;
    box-shadow: none !important;
}

.rhap_volume-controls {
    justify-content: left !important;
}

.display-flex-space-between {
    display: flex;
    justify-content: space-between;
}

.text-justify {
    text-align: justify;
}

.bordered-black {
    border: 1px solid black;
    width: 50%;
    margin-top: 2vh;
}

.text-pre-line {
    white-space: pre-line
}
